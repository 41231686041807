import './App.css';
import Clock from './components/Clock';
import Weather from './components/Weather';

function App() {

  const forceRefresh = () => {
    window.location.reload();
  }

  // Refresh every 5 minutes
  setInterval(forceRefresh, 300000);

  return (
    <div 
      className="App"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        fontFamily: 'Roboto Mono',
      }}
    >
      <Clock />
      <Weather />
    </div>
  );
}

export default App;
