import { useState } from 'react'

export default function Clock() {
  const options = {
    hour: '2-digit', 
    minute:'2-digit', 
    second: '2-digit'
  } as any;
  let time  = new Date().toLocaleTimeString([], options)

  const [ctime,setTime] = useState(time);

  const UpdateTime=()=>{
    time =  new Date().toLocaleTimeString([], options)
    setTime(time.replace('PDT', ''))
  }

  setInterval(UpdateTime)

  return (
    <div>
      <p
        style={{
          fontSize: '12vw',
          fontWeight: 'bold',
          padding: 0,
          margin: 0,
        }}
      >
        {ctime}
      </p>
    </div>
  )
}
